<template>
    <div class="card-tail flex flex-col self-start">
        <div class="border-b pb-2 mb-4">
            <h1>Komentar</h1>
        </div>
        <div class="px-16">
            <div class="mb-8" v-for="comment in commentsData" :key="comment.commentId">
                <div class="flex justify-between px-2 text-sm text-gray-500">
                    <p>
                        {{ comment.individualName }}
                    </p>
                    <p>
                        {{ formatDate(comment.createdDate) }}
                    </p>
                </div>
                <p class="p-4 bg-gray-100 rounded-lg">
                    {{ comment.text }}
                </p>
            </div>

            <div class="border-t pt-8">
                <el-form ref="form" :model="formData" :rules="rules">
                    <el-form-item prop="newComment">
                        <el-input v-model="formData.newComment" type="textarea" placeholder="Lägg till ny kommentar" resize="none" rows="5" />
                    </el-form-item>
                </el-form>
                <el-button @click="addNewComment" type="primary" :loading="$waiting.is('comments.loading')">Lägg till</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../bank_files.api";

export default {
    props: {
        sourceId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            commentsData: [],
            formData: {
                newComment: "",
            },
            rules: {
                newComment: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$waiting.start("comments.loading");
            this.commentsData = await Api.getCommentsBySourceId(this.sourceId);
            this.$waiting.end("comments.loading");
        },

        async addNewComment() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("comments.loading");
            await Api.addNewComment(this.sourceId, this.formData.newComment);
            this.$notify.success({ title: "Utfört" });
            this.formData.newComment = "";
            this.getAll();
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>
